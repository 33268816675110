<template>
  <b-overlay :show="loading">
    <div
      :class="options.showLabel ? 'form-group': 'form-control'"
      style="display:block;"
    >
      <label v-if="options.showLabel">{{ options.label }}</label>
      <vue-editor
        v-model="field.value"
        use-custom-image-handler
        :editor-options="editorSettings"
        @image-added="onImageAdded"
      />
      <!--    <textarea
        v-model="field.value"
        :class="options.showLabel ? 'form-control' : 'without-label'"
        :placeholder="options.label"
        :disabled="!options.enabled"
        @change="onChange(field)"
      />-->
    </div>
  </b-overlay>
</template>

<script>
import { VueEditor, Quill } from 'vue2-editor'
import { BOverlay } from 'bootstrap-vue'
import Multimedia from '@/dc-it/models/Multimedia'
// import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'

// Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)

export default {
  name: 'HTMLInput',
  components: {
    VueEditor,
    BOverlay,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      editorSettings: {
        modules: {
          // imageDrop: true,
          imageResize: {
            modules: ['Resize', 'DisplaySize'],
          },
        },
      },
    }
  },
  methods: {
    onChange(field) {
      this.$emit('change', field)
    },
    onImageAdded(image, Editor, cursorLocation, resetUploader) {
      this.loading = true
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = () => {
        const m = new Multimedia()
        m.setAttr('filename', image.name)
        m.setAttr('file_url', reader.result)

        m.save().then(multimedia => {
          Editor.insertEmbed(cursorLocation, 'image', multimedia.data.file_url)
          resetUploader()
          this.loading = false
        })
      }
    },
  },
}
</script>

<style>
    textarea {
        resize: none;
        height: 150px !important;
    }
</style>
