<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div v-if="!loading">
          <form-component
            :model="model"
            :show-cancel-button="false"
            @save-form="save"
          />
        </div>
        <div v-if="loading">
          <div class="d-flex align-items-center justify-content-center">
            <b-spinner
              class="mr-1"
              variant="primary"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormComponent from '@/dc-it/components/FormComponent.vue'
import Configuration from '@/dc-it/models/Configuration'
import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'ConfigurationView',
  components: { FormComponent, BSpinner },
  data() {
    return {
      model: null,
      loading: true,
    }
  },
  mounted() {
    Configuration.get().then(configurations => {
      this.model = configurations.length === 1 ? configurations[0] : new Configuration()
      this.loading = false
    })
  },
  methods: {
    save() {
      this.$swal({
        title: 'Aviso',
        text: 'Se ha guardado correctamente',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        confirmButtonColor: '#41b882',
      })
    },
  },
}
</script>

<style scoped>

</style>
