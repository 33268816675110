import MultipleMultimedia from '@/dc-it/types/MultipleMultimedia'
import Multimedia from '@/dc-it/models/Multimedia'
import Model from './Model'
import Field from '../types/Field'
import HTMLField from '../types/HTMLField'

export default class Config extends Model {
    endpoint = 'configurations';

    related = ['multimedia']

    fields = [
      new Field('id', 'Código').disabled(),
      new HTMLField('about_us_es', 'Nosotros (español)'),
      new HTMLField('about_us_en', 'Nosotros (inglés)'),
      new HTMLField('terms_es', 'Términos y condiciones (español)'),
      new HTMLField('terms_en', 'Términos y condiciones (inglés)'),
      new HTMLField('privacy_notice_es', 'Aviso de privacidad (español)'),
      new HTMLField('privacy_notice_en', 'Aviso de privacidad (inglés)'),

      new HTMLField('professional_seller_es', 'Vendedor profesional (español)'),
      new HTMLField('professional_seller_en', 'Vendedor profesional (inglés)'),

      new HTMLField('internal_product_shipment_es', 'Texto de envío para producto interno (español)'),
      new HTMLField('internal_product_shipment_en', 'Texto de envío para producto interno (inglés)'),
      new HTMLField('external_product_shipment_es', 'Texto de envío para producto externa (español)'),
      new HTMLField('external_product_shipment_en', 'Texto de envío para producto externa (inglés)'),
      new HTMLField('payment_terms_es', 'Términos y condiciones de pago (español)'),
      new HTMLField('payment_terms_en', 'Términos y condiciones de pago (inglés)'),
      new HTMLField('return_terms_es', 'Términos y condiciones de devoluciones (español)'),
      new HTMLField('return_terms_en', 'Términos y condiciones de devoluciones (inglés)'),
      new HTMLField('authentication_es', 'Autenticidad (español)'),
      new HTMLField('authentication_en', 'Autenticidad (inglés)'),

      new Field('seller_text_es', 'Texto banner de vendedores Home (español)'),
      new Field('seller_text_en', 'Texto banner de vendedores Home (inglés)'),
      new MultipleMultimedia('multimedia', 'Imagen', Multimedia).hide().setLimit(1),

      new Field('entrupy_certificate_price', 'Precio de certificado Entrupy', 'number'),
      new Field('free_certificate_min_price', 'Precio mínimo para certificado Entrupy gratuito', 'number'),
    ]

    clone = () => Config;
}
